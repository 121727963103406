module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../gatsby-source-umbraco-phoenix/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/codebuild/output/src489875512/src/phx-finish-uk/packages/gatsby-source-umbraco-phoenix/.gatsby","rootPath":"/codebuild/output/src489875512/src/phx-finish-uk/packages/gatsby-source-umbraco-phoenix","xOrigin":["https://phx-finish-cl-uat.husky-2.rbcloud.io/"],"baseURL":"https://phx-finish-cl-uat.husky-2.rbcloud.io/","siteURL":"https://develop.d2tdwcn4xiau5q.amplifyapp.com/"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Finish","short_name":"Finish","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/codebuild/output/src489875512/src/phx-finish-uk/packages/gatsby-theme-husky/static/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1c397445a47927c6f5c10af0a2454d38"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://develop.d2tdwcn4xiau5q.amplifyapp.com/","stripQueryString":true},
    },{
      plugin: require('../../gatsby-theme-husky/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/codebuild/output/src489875512/src/phx-finish-uk/packages/gatsby-theme-husky/.gatsby","projectRoot":"/codebuild/output/src489875512/src/phx-finish-uk/packages/gatsby-theme-husky","babel":false,"tsNode":true},
    },{
      plugin: require('../../gatsby-theme-husky/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{}},
    }]
